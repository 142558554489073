<template>
  <div class="order">
    <span class="bold">排序方式：</span>
    <div
      class="flex"
      v-for="(item, index) in orderItems"
      @mouseover="overStyle(item)"
      @mouseleave="leaveStyle(item)"
      @click="handleSort(item)"
      :key="item.id"
    >
      <div :class="{ pxfs_actived: activedItem.id == item.id }" class="pointer pxfs">
        {{ item.name }}
      </div>
      <img v-if="getOrderImg(item)" :src="getOrderImg(item)" />
      <div v-else style="margin-left: 10px"></div>
      <div v-show="index != orderItems.length - 1" class="splitline"></div>
    </div>
    <!-- <div
      :class="{ pxfs_actived: sortType == 'publish_date' }"
      @mouseover="overDateStyle"
      @mouseleave="leaveDateStyle"
      @click="handleSort('publish_date')"
      class="pointer pxfs"
    >
      出版日期
      <img :src="jiangxuSrc" />
    </div>
    <div
      style="
        width: 1px;
        margin-left: 15px;
        margin-top: 3px;
        height: 18px;
        background: #cccccc;
      "
    ></div>
    <div
      :class="{ pxfs_actived: sortType == 'CONVERT(title using gbk)' }"
      @mouseover="overStyle"
      @mouseleave="leaveStyle"
      @click="handleSort('CONVERT(title using gbk)')"
      class="ml15 pointer pxfs"
    >
      书名
      <img :src="shengxuSrc" />
    </div> -->
  </div>
</template>

<script>
import jiangxu from "@/assets/images/icon-jiangxu-default.png";
import jiangxued from "@/assets/images/icon-jiangxu-select.png";
import shengxu from "@/assets/images/icon-shengxu-default.png";
import shengxued from "@/assets/images/icon-shengxu-select.png";
export default {
  name: "order",
  data() {
    return {
      orderList: [],
      activedItem: {},
      sortType: "score",
      jiangxuSrc: jiangxued,
      shengxuSrc: shengxu,
      jiangxu,
      jiangxued,
      shengxu,
      shengxued,
      queryForm: {
        searchWord: "", //检索词
        searchBarWord: "", //检索条-检索词 字段默认为题名、作者、ISBN
        searchField: "title,author", //检索字段，先固定值
        subjectIds: [], //左侧学科
        clcIds: [], //左侧中图法
        publishYear: null, //出版年
        pageNum: 1, //页码 1
        pageSize: 10, //页面大小 10
        orderByColumn: "state_time", //排序字段 默认出版日期
        isAsc: "desc", //排序方式 默降序排序
      },
    };
  },
  props: {
    orderItems: {
      type: Array,
      default() {
        return [
          {
            id: "state_time",
            asc: false,
            desc: false,
            name: "上架时间",
            value: "desc",
          },
          {
            id: "score",
            asc: false,
            desc: false,
            name: "相关度",
            value: "desc",
          },
          {
            id: "CONVERT(title using gbk)",
            asc: true,
            desc: true,
            name: "书名",
            value: "asc",
          },
          {
            id: "publish_date",
            asc: true,
            desc: true,
            name: "出版日期",
            value: "desc",
          },
        ];
      },
    },
    defaultItem: {
      type: Object,
      default() {
        return {
          id: "state_time",
          asc: false,
          desc: false,
          name: "上架时间",
          value: "desc",
        };
      },
    },
  },
  methods: {
    getOrderImg(item) {
      // 判断该排序是否具有升序或者降序，没有则不显示箭头
      if (item.asc || item.desc) {
        // 判断是降序还是升序
        if (item.value == "desc") {
          if (item.desc) {
            // 判断排序是否被选中
            if (item.id == this.activedItem.id || item.mouseover) {
              // 被选中的排序
              return this.jiangxued;
            } else {
              return this.jiangxu;
            }
          } else {
            // 没有降序箭头=理论上不应该出现此情况，数据有问题请排查
            return null;
          }
        } else if (item.value == "asc") {
          if (item.asc) {
            // 判断排序是否被选中
            if (item.id == this.activedItem.id || item.mouseover) {
              // 被选中的排序
              return this.shengxued;
            } else {
              return this.shengxu;
            }
          } else {
            // 没有升序箭头=理论上不应该出现此情况，数据有问题请排查
            return null;
          }
        }
      } else {
        return null;
      }

      // 判断是否选中
    },
    overStyle(item) {
      this.orderItems.forEach((element, index) => {
        if (element.id == item.id) {
          element.mouseover = true;
          this.$set(this.orderItems, index, element);
        }
      });
    },
    leaveStyle(item) {
      this.orderItems.forEach((element, index) => {
        if (element.id == item.id) {
          element.mouseover = false;
          this.$set(this.orderItems, index, element);
        }
      });
    },
    // 排序
    handleSort(item) {
      if (item.id == this.activedItem.id) {
        if (item.asc && item.desc) {
          item.value = item.value == "desc" ? "asc" : "desc";
        }
      }
      this.activedItem = item;
      this.$emit("click", item);
    },
  },
  watch: {
    orderItems: {
      handler: function (n, o) {
        this.orderList = n;
      },
      immediate: true,
      deep: true,
    },
    defaultItem: {
      handler: function (n, o) {
        this.activedItem = n;
        this.orderList.forEach((element, index) => {
          if (element.id == n.id) {
            element.value = n.value;
            this.$set(this.orderList, index, element);
          }
        });
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.bold {
  font-size: 16px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: bold;
  text-align: left;
  color: #333333;
}
.pxfs {
  color: #333333;
  &:hover {
    color: #078bef;
  }
}
.pxfs_actived {
  color: #078bef;
}
.order {
  display: flex;
  .pointer {
    font-size: 16px;
    font-family: Microsoft YaHei, Microsoft YaHei-Regular;
    font-weight: 400;
    text-align: left;

    user-select: none;
    cursor: pointer;
    line-height: 22px;
  }
  img {
    width: 10px;
    height: 13px;
    margin: auto 10px;
  }
  .splitline {
    width: 1px;
    margin-top: 3px;
    height: 18px;
    background: #cccccc;
    margin-right: 10px;
  }
}
</style>
